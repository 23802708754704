/*! style.css - portfolio */


/* GLOBAL STYLES
----------------------------------*/
html,
body {
    width: 100%;
}

body,
header,
footer {
    font-family: Roboto, Calibri, Arial, Helvetica, sans-serif;
    color: #424242;
    background-color: #ddd;
}

h1, h2, h3, h4, h5, h6 {
    font-family: Merriweather, Cambria, Georgia, serif;
}

header {
    color: #fff;
    background-color: #4c5567;
    text-align: center;
    margin: 0;
    padding: 16px 0 16px 0;
    margin: 0 0 8px 0;
}

header h1 {
    margin: 0;
    padding: 0;
}

header h3,
header h4 {
    color: #a7afbe;
    margin: 0;
    padding: 0;
}

main h4 {
    text-align: center;
}

footer {
    color: #a7afbe;
    background-color: #4c5567;
    text-align: center;
    font-size: 14px;
    padding: 14px;
}

footer a {
    color: #d3d7df;
}

footer a:hover {
    color: #d3d7df;
    text-decoration: underline;
}

figure,
figcaption {
    display: block;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: underline;
}

a:hover {
    color: #80bd01;
    text-decoration: none;
}


/* PROJECTS GRID
----------------------------------*/
.grid-heading {
    margin: 2rem;
    border-bottom: 2px solid hsla(0,0%,4%,.25);
    padding: 0 2rem 0;
}

.grid-heading h4 {
    text-align: left;
    font-weight: bold;
    letter-spacing: 2px;
    font-family: Roboto, Calibri, Arial, Helvetica, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.grid {
    margin: 0 0 0 -10px; /* gutter for block */
    min-width: 290px;
}

.grid-item {
    padding: 0 0 10px 10px; /* gutter for each element */
    width: 33.333%;
    float: left;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

@media only screen and (max-width: 992px) {
    .grid-item {
        width: 50%;
    }
    .grid-heading h4 {
        text-align: center;
    }
}

@media only screen and (max-width: 640px) {
    .grid-item {
        width: 100%;
    }
}

@media only screen and (max-width: 320px) {
    .grid-tags {
        display: none;
    }
}

.grid-item > a {
    display: block;
    position: relative;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    padding: 0;
}

.grid-image {
    position: relative;
    overflow: hidden;
}

.grid-image > img {
    width: 100%;
    display: block;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}

.grid-item:hover .grid-image > img {
    transform: scale(1.05);
}

.grid-caption {
    width: 100%;
    padding: 20px 20px 0 20px;
    -webkit-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 20px;
    left: 0;
    text-align: center;
    overflow: hidden;
    opacity: 0; /* hides caption */
    transition: opacity 0.7s ease;
}

.grid-item:hover .grid-caption {
    opacity: 1; /* reveals caption */
    z-index: 3;
}

.grid-item .grid-image:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(250,250,250, 0);
    -webkit-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}

.grid-item:hover .grid-image:after {
    background: rgba(20,20,20, .85);
}

.grid-item:hover .grid-caption,
.grid-item:hover .grid-title {
    color: #fff;
}

.grid-item:hover .grid-detail {
    color: #aaa;
}

.grid-title {
    color: #fff;
    margin: 0 0 8px 0;
    padding: 0;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    line-height: 1.2;
    transition: transform 0.7s ease;
    transform: translateY(-100%);
}

.grid-item:hover .grid-title {
    transform: translateY(0);
}

.grid-detail {
    color: #aaa;
    margin-bottom: 25px;
    font-size: 13px;
    text-align: center;
    line-height: 1.2;
    transition: transform 0.7s ease;
    transform: translateY(-100%);
}

.grid-item:hover .grid-detail {
    transform: translateY(0);
}

.grid-tags .label {
    margin-bottom: 5px;
    opacity: 0;
    transition: transform 0.9s ease, opacity 0.5s ease-in-out;
    transform: translateY(200%);
}

.grid-item:hover .grid-tags .label {
    opacity: 1;
    transform: translateY(0);
}

.grid-item:hover .grid-tags .label:first-child {
    transition-delay: 0.1s;
}

.grid-item:hover .grid-tags .label:nth-child(2) {
    transition-delay: 0.2s;
}

.grid-item:hover .grid-tags .label:nth-child(3) {
    transition-delay: 0.3s;
}

.grid-item:hover .grid-tags .label:nth-child(4) {
    transition-delay: 0.4s;
}

.grid-item:hover .grid-tags .label:nth-child(5) {
    transition-delay: 0.5s;
}

.grid-item:hover .grid-tags .label:nth-child(6) {
    transition-delay: 0.6s;
}

.grid-item:hover .grid-tags .label:nth-child(7) {
    transition-delay: 0.7s;
}

.grid-item:hover .grid-tags .label:nth-child(8) {
    transition-delay: 0.8s;
}

.grid-item:hover .grid-tags .label:nth-child(9) {
    transition-delay: 0.9s;
}

.grid-item:hover .grid-tags .label:nth-child(10) {
    transition-delay: 1s;
}

/* MODAL INFO
----------------------------------*/
.info-image {
    /*border: 2px dashed red;*/
}

.info-text {
    /*border: 1px dashed blue;*/
    margin-top: 32px;
}

.info-desc,
.info-no-gutter {
    margin: 0;
    padding: 0;
}

.info-icons {
    font-weight: bold;
}

.info-text .fi-wrench,
.info-text .fi-social-github,
.info-text .fi-play-circle,
.info-text .fi-page,
.info-text .fi-calendar {
    font-size: 22px;
    font-weight: normal;
}

.info-text .fi-social-github {
    margin-left: 14px;
}
